<template>
<aheader/>
<section class="section">
  <div class="columns">
    <div class="column is-10 is-offset-1">
      <div class="title mt-4">
        特定商取引法に基づく表記
      </div>
      <table class="table is-bordered">
        <tbody class="has-text-left">
          <tr>
            <th>
              販売業者
            </th>
            <td>
              株式会社 おぐら屋
            </td>
          </tr>
          <tr>
            <th>
              代表責任者
              <br>代表取締役
            </th>
            <td>
              義平 稔
            </td>
          </tr>
          <tr>
            <th>
              所在地
            </th>
            <td>
              〒535-0013
              <br>大阪府大阪市旭区森小路2丁目9番2号
            </td>
          </tr>
          <tr>
            <th>
              電話番号
            </th>
            <td>
              <a href="tel:066-954-1515">06-6954-1515</a>
            </td>
          </tr>
          <tr>
            <th>
              メールアドレス
            </th>
            <td>
              <a href="mailto:info@ogurayafuton.com">info@ogurayafuton.com</a>
            </td>
          </tr>
          <tr>
            <th>
              URL
            </th>
            <td>
              <a href="https://www.ogurayafuton.com">https://www.ogurayafuton.com</a>
            </td>
          </tr>
          <tr>
            <th>
              商品代金以外の必要料金
            </th>
            <td>
              商品お届けにあたり、送料が必要となります。<br>
              北海道・沖縄：1800円、東北地方：1500円、関東・四国・九州地方：1300円、
              <br>中部・近畿・中国地方：1200円
            </td>
          </tr>
          <tr>
            <th>
              注文方法
            </th>
            <td>
              インターネットショップでのみ、受け付けております。
              <br>お電話・メールでのご注文はお受けできませんので、ご了承ください。
              <br>ご注文の受付が完了しましたら、ご注文内容確認のメールをお送りいたします。
              <br>また、ご注文内容に不備がある場合はご連絡させていただく事があります。
              <br>予めご了承宜しくお願いいたします。
            </td>
          </tr>
          <tr>
            <th>
              お支払方法
            </th>
            <td>
              クレジットカード
              <br>JCB・VISA・MASTERA・MERICAN EXPRESS・DINERS
            </td>
          </tr>
          <tr>
            <th>
              支払期限
            </th>
            <td>
              クレジットカード各会社の約定通りです。
            </td>
          </tr>
          <tr>
            <th>
              引き渡し時期
            </th>
            <td>
              ご注文後に弊社にてご入金が確認できてから通常7日程度で発送いたします。お届けまで通常を大幅に超えると予想される場合にはご登録いただきましたメールにてご連絡いたします。
            </td>
          </tr>
          <tr>
            <th>
              返品・交換について
            </th>
            <td>
              注文の確定前であれば、画面でキャンセルの操作をしていただくことで、注文の取り消しが可能です。注文の確定後のキャンセルはお受けできない場合がありますこと、ご了承ください。商品には万全を期しておりますが、万一お届けした商品に瑕疵があった場合、商品到着後７日以内にお問い合わせフォームよりご連絡ください。お問い合わせフォームよりご連絡いただいた場合に限り同一商品とのご交換を賜ります。なお、商品の生産終了や在庫切れ等で交換商品の手配が困難な場合はご返金にて対応させていただきます。
              また、お客様のご都合による商品の返品・交換はお受けいたしておりません。
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<afooter/>
</template>
<script>
export default {
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>
<style scoped>
th {
  width:20vw;
  padding:8px;
  vertical-align: top;
  background: rgb(224, 224, 224);
}
</style>